import { theme as chakraTheme } from '@chakra-ui/react';

const { Button, Checkbox, Container, Divider, Heading, Input, Modal, Popover, Textarea } =
  chakraTheme.components;

const components = {
  Button,
  Checkbox,
  Container,
  Divider,
  Heading,
  Input,
  Modal,
  Popover,
  Textarea,
};

export default components;
